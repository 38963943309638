@import '../_palette.scss';
.album {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 33% 33% 33%;
    grid-auto-rows: minmax(150px, auto);
    background-color: #fff;
    color: #444;
    width: calc(100% - 30px);
    min-height: 640px;
    margin-top: 15px;
    .albumItem {
        background-size: cover;
        .albumHeader {
            background-color: rgba(black, 0.18);
            padding: 20px 10px;
            text-align: center;
            color: white;
            p,
            h3 {
                margin: 0;
            }
            h3 {
                text-transform: uppercase;
                text-shadow: 1px 1px black;
            }
        }
        text-decoration: none;
        &:first-child {
            grid-column: 1;
            grid-row: 1 / 3;
        }
        cursor: pointer;
    }
    @media screen and (max-width: $contentWidth) {
        grid-template-columns: 50% 50%;
        width: calc(100% - 20px);
    }
    @media screen and (max-width: $mobileWidth) {
        grid-template-columns: 100%;
        width: 100%;
    }
}