@import '../_palette.scss';
.loader {
    width: 100%;
    min-height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        height: 40px;
        margin-top: -3px;
        margin-bottom: -5px;
        padding: 0 30px;
        -webkit-animation: rotation 2s infinite linear;
    }
}

@-webkit-keyframes rotation {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(359deg);
    }
}