@import '../_palette.scss';
.subHeader {
    width: 100%;
    height: 110px;
    background-color: $primary;
    text-align: center;
    color: white;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;

    h1 {
        text-transform: uppercase;
        width: 100%;
        margin: 0;
        padding: 0;
    }
    p {
        margin: 0;
        padding: 0;
        width: 100%;
    }
}