@import "../_palette.scss";
.subNav {
  width: calc(100% - 30px);
  display: flex;
  margin: 18px 15px;
  justify-content: space-between;
  a {
    text-decoration: none;
  }
  h2 {
    color: rgba(black, 0.21);
    cursor: pointer;
    &.active,
    &:hover {
      color: $primary;
    }
  }
  @media screen and (max-width: $contentWidth) {
    flex-wrap: wrap;
    h2 {
      font-size: 1.3em;
    }
  }
  @media screen and (max-width: $mobileWidth) {
    a {
      width: 50%;
      h2 {
        font-size: 1em;
      }
      &:nth-child(even) {
        text-align: right;
      }
    }
  }
}
