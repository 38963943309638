@import '../_palette.scss';
.backgroundFade {
    width: 100%;
    $height: 650px;
    height: $height;
    .backgroundImage {
        z-index: -5;
        position: absolute;
        width: 100%;
        height: $height;
        background-size: cover;
        background-position: center;
    }
    .fader {
        z-index: -5;
        position: absolute;
        width: 100%;
        height: $height - 300px;
        background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.43) 43%, $primary 100%);
        background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.43) 43%, $primary 100%);
        background: linear-gradient(to top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.43) 43%, $primary 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=0);
    }
}