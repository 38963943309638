@import url("https://fonts.googleapis.com/css?family=Lato:300,400");
@font-face {
  font-family: "Gilroy";
  src: url("/fonts/GilroyExtraBold.eot");
  src: url("/fonts/GilroyExtraBold.eot?#iefix") format("embedded-opentype"),
    url("/fonts/GilroyExtraBold.woff2") format("woff2"), url("/fonts/GilroyExtraBold.woff") format("woff"),
    url("/fonts/GilroyExtraBold.otf") format("opentype");
}

h1,
h2,
h3,
h4,
h5,
h6,
label,
button {
  font-family: "Gilroy";
}

p,
b,
input,
textarea,
ul,
li,
a,
span {
  font-family: "Lato", sans-serif;
  font-weight: 300;
}

.tippy-tooltip-content {
  background-color: #20635C;
  border-radius: 25px;
  color: white;
  padding: 5px 15px;
  font-family: "Lato", sans-serif;
  font-weight: 300;
  transition: all 0.2s ease-in-out;
  margin-top: -24px;
}
