@import '../_palette.scss';
.contactForm {
    width: 60%;
    margin: 0 auto;
    padding: 20px 0;
    @media screen and (max-width: $contentWidth) {
        width: 100%;
    }
    h1 {
        text-transform: uppercase;
        text-align: center;
        color: white;
        font-size: 1.5em;
        margin: 0;
    }
    p {
        color: white;
        text-align: center;
        margin: 0;
    }
    .inputs {
        display: flex;
        justify-content: space-between;
    }
    label {
        color: rgba(white, 0.75);
        font-size: 1em;
        font-family: 'Lato';
        font-weight: 300;
    }
    .input {
        width: 100%;
        margin: 15px;
        &:first-child {
            margin-left: 0
        }
        &:last-child {
            margin-right: 0
        }
        input {
            border: none;
            width: calc(100% - 20px);
            padding: 10px;
            margin-top: 4px;
            transition: all 0.2s ease-in-out;
            &:focus {
                outline: none
            }
            &:disabled {
                opacity: 0.6
            }
        }
    }
    .inputFile {
        input {
            opacity: 0;
        }
    }
    .loader {
        height: 30px;
        width: 30px;
        margin: -10px;
        transition: all 0.2s ease-in-out;
        animation: {
            name: spinner;
            duration: 2s;
            iteration-count: infinite;
        }
    }
    .textarea {
        textarea {
            margin-top: 4px;
            width: calc(100% - 20px);
            height: 120px;
            border: none;
            padding: 10px;
            transition: all 0.2s ease-in-out;
            &:focus {
                outline: none
            }
            &:disabled {
                opacity: 0.6
            }
        }
    }
    .buttonContainer {
        display: flex;
        justify-content: center;
        margin-top: 20px;
        @media screen and (max-width: $contentWidth) {
            display: block;
			width: calc(100% - 20px);
        }
    }
    .fileUpload {
        text-align: center;
        width: 100%;
        display: block;
        margin: 10px 0;
        text-decoration: underline;
    }
    @media screen and (max-width: $mobileWidth) {
        .inputs {
            flex-wrap: wrap;
        }
        .input {
            margin-top: 6px;
            &:first-child {
                margin-left: 15px;
            }
            &:last-child {
                margin-right: 15px;
            }
        }
        .textarea {
            margin-top: 6px;
            label {
                margin-left: 15px;
            }
            textarea {
                width: calc(100% - 50px);
                margin: 4px 15px;
            }
        }
        .buttonContainer {
            margin: 0 10px
        }
    }
    .error {
        color: red;
    }
    .success {
        color: darken($primary, 10%);
        margin: 7px;
    }
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(180deg);
    }
    100% 0% {
        transform: rotate(360deg);
    }
}