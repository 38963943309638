@import '../_palette.scss';
.buttonContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    button {
        padding: 15px 30px;
        border: 3px solid $primary;
        background: none;
        border-radius: 1000px;
        font-weight: 900;
        color: $primary;
        min-width: 200px;
        cursor: pointer;
        &:focus {
            outline: none;
        }
    }
    img {
        height: 20px;
        margin-top: -3px;
        margin-bottom: -5px;
        padding: 0 30px;
        -webkit-animation: rotation 2s infinite linear;
    }
    &.invert {
        button {
            color: white;
            border: 3px solid white;
        }
    }
    @media screen and (max-width: $mobileWidth) {
        button {
            width: 100%;
            margin-top: 20px;
        }
    }
}

@-webkit-keyframes rotation {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(359deg);
    }
}