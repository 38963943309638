@import "../_palette.scss";
.hero {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 435px;
    background: {
        repeat: no-repeat;
        position: right;
        size: 100%;
    }
    .message {
        width: 55%;
        height: 100%;
        border-radius: 0 1000px 1000px 0;
        background-color: $primary;
        color: white;
        text-transform: uppercase;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 1.8em;
        h1 {
            max-width: 400px;
            margin-left: calc((100vw - #{$contentWidth}) / 2);
        }
    }
    video {
        position: absolute;
        width: 100%;
        top: 0;
        right: 0;
        z-index: -1;
    }
    .offer {
        position: absolute;
        top: 15%;
        right: 5%;
        height: 80%;
    }
    @media screen and (max-width: $contentWidth) {
        height: calc(40vw + 100px);
        .message {
            width: 100%;
            border-radius: 0;
            height: 100px;
            justify-content: center;
            h1 {
                text-align: center;
                width: 100%;
                max-width: 100%;
                font-size: 1.2em;
                margin-left: 0;
            }
        }
        background: {
            size: 100% auto;
            position: center;
        }
        video {
            width: calc(130vw + 100px);
        }
    }
    @media screen and (max-width: $mobileWidth) {
        .message {
            h1 {
                font-size: 0.8em;
                margin: 0 30px;
            }
        }
    }
}
