@import '../_palette.scss';
.section {
    margin-top: 40px;
    display: flex;
    .content {
        width: 100%;
    }
    h2 {
        text-transform: uppercase;
        margin-bottom: 0px;
    }
    .sectionImage {
        margin-top: 20px;
        max-height: 100%;
        width: 44%;
        padding-left: 30px;
        object-fit: contain;
    }
    &.right {
        flex-direction: row-reverse;
        .sectionImage {
            padding-left: 0;
            padding-right: 30px;
        }
    }
    &.center {
        text-align: center;
        justify-content: center;
    }
    @media screen and (max-width: $contentWidth) {
        padding: 20px;
        flex-direction: column !important;

        .sectionImage {
            padding: 0 5% !important;
            height: auto;
            width: auto; // width: calc(100% - 100px);
        }
    }
}