@import "../_palette.scss";
.fullContainer {
  width: 100%;
  background-color: $light;
  padding: 20px 0;
  .container {
    margin: 0 auto;
    &.flex {
      display: flex;
      flex-wrap: wrap;
    }
  }
  &.primary {
    background-color: $primary;
  }
}
