@import "../_palette.scss";
.adventureGrid {
    width: 100%;
    min-height: 600px;
    display: flex;
    flex-wrap: wrap;
    @media screen and (max-width: $contentWidth) {
        width: calc(100% - 20px);
        margin: 0 10px;
    }
    @media screen and (max-width: $mobileWidth + 150) {
        justify-content: center;
    }
    .adventureItem {
        a {
            text-decoration: none !important;
            h1,
            h2,
            h3,
            h4,
            p,
            b {
                color: black;
            }
        }
        position: relative;
        width: calc(33% - 14px);
        height: 515px;
        text-align: center;
        background-color: white;
        margin-right: 25px;
        margin-bottom: 50px;
        box-shadow: 2px 2px 7px #00000024;
        cursor: pointer;
        @media screen and (min-width: $contentWidth) {
            &:nth-child(3n) {
                margin-right: 0;
            }
        }
        @media screen and (max-width: $contentWidth) and (min-width: $mobileWidth + 151) {
            width: calc(50% - 14px);
            &:nth-child(2n) {
                margin-right: 0;
            }
        }
        @media screen and (max-width: $mobileWidth + 150) {
            width: calc(100% - 14px);
            margin: 0 0 40px 0;
        }
        .adventureImage {
            width: 100%;
            height: 55%;
            background-size: cover;
            background-position: center;
        }
        .arrows {
            position: absolute;
            width: 100%;
            top: calc(55% / 2 - 30px);
            display: flex;
            justify-content: space-between;
            img {
                padding: 20px;
                cursor: pointer;
            }
            .left {
                transform: rotate(180deg);
            }
            animation: fadeIn 0.2s linear;
        }
        .grade {
            width: 100%;
            height: 22px;
            display: flex;
            span {
                padding-top: 3px;
                font-size: 0.8em;
                width: 100%;
                border-right: 1px solid darken($light, 5);
                color: rgba(black, 0.4);
                &:nth-child(10) {
                    border-right: 0;
                }
                background-color: $light;
                &.active {
                    color: white;
                    background-color: $primary;
                }
            }
            .label {
                display: none;
                position: absolute;
                background-color: $primary;
                color: white;
                margin-top: -15px;
                padding: 10px 0;
                width: 100%;
                &:hover {
                    display: block;
                }
            }
            .scale {
              position: absolute;
              width: calc(100% - 10px);
              display: flex;
              justify-content: space-between;
              margin: 12px 5px 0 5px;
              font-size: 0.8em;
              opacity: 0.4;
            }
        }
        .contents {
            max-height: 126px;
            overflow: hidden;
            padding: 5px 30px 10px 30px;
            text-align: left;
            h3 {
                margin-bottom: 0;
            }
            p {
                margin: 2px;
            }
        }
        .activities {
            color: lighten($primary, 10);
        }
        .price {
            position: absolute;
            right: 10px;
            bottom: 24px;
            color: $primary;
            font-family: "gilroy";
            font-weight: 900;
            font-size: 1.3em;
        }
        .altPrice {
            position: absolute;
            right: 10px;
            bottom: 11px;
            color: $primary;
            font-family: "gilroy";
            font-weight: 900;
            font-size: 1em;
            opacity: 0.7;
        }
        .length {
            opacity: 0.7;
            position: absolute;
            left: 10px;
            bottom: 11px;
            color: $primary;
            font-family: "gilroy";
            font-weight: 900;
            font-size: 1.3em;
            p {
                display: inline;
                font-size: 0.6em;
                font-family: "gilroy";
                padding: 0;
                margin: 0;
                color: $primary;
                opacity: 0.7;
            }
        }
        .flight {
            position: absolute;
            right: 10px;
            bottom: 46px;
            color: $primary;
            font-family: "gilroy";
            font-weight: 900;
            font-size: 0.5em;
        }
        .disclaimer {
            position: absolute;
            right: 10px;
            bottom: 5px;
            color: $primary;
            font-family: "gilroy";
            font-weight: 900;
            font-size: 0.5em;
            opacity: 0.7;
        }
        button {
            position: absolute;
            height: 40px;
            width: 40%;
            border: none;
            background-color: $primary;
            border-radius: 1000px;
            color: white;
            font-family: "gilroy";
            font-weight: 900;
            bottom: -20px;
            left: 30%;
            cursor: pointer;
            &:focus {
                outline: none;
            }
        }
    }
}

.adventureList {
    width: 100%;
    min-height: 600px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    $height: 122px;
    .adventureItem {
        position: relative;
        width: calc(100% - #{$height});
        padding-left: $height;
        padding-right: 190px;
        height: $height;
        text-align: left;
        background-color: white;
        margin-bottom: 25px;
        box-shadow: 2px 2px 7px #00000024;
        cursor: pointer;
        a {
            text-decoration: none !important;
            h1,
            h2,
            h3,
            h4,
            p,
            b {
                color: black;
            }
        }
        .adventureImage {
            position: absolute;
            width: $height;
            height: 100%;
            left: 0;
            background-size: cover;
            background-position: center;
        }
        .arrows {
            position: absolute;
            width: $height;
            top: calc(55% / 2);
            display: flex;
            justify-content: space-between;
            left: 0;
            img {
                padding: 20px;
                cursor: pointer;
            }
            .left {
                transform: rotate(180deg);
            }
            animation: fadeIn 0.2s linear;
        }
        .grade {
            width: calc(100% + 190px);
            height: 5px;
            display: flex;
            font-size: 0;
            span {
                width: 100%;
                background-color: $light;
                &.active {
                    background-color: $secondary;
                }
            }
            .label {
                display: none;
                position: absolute;
                background-color: $primary;
                color: white;
                margin-top: -15px;
                left: $height + 10px;
                padding: 10px;
                &:hover {
                    display: block;
                }
            }
        }
        .contents {
            padding: 0px 30px;
            h3 {
                margin-bottom: 0;
            }
            p {
                margin: 2px;
            }
        }
        .activities {
            color: lighten($primary, 10);
        }
        .price {
            position: absolute;
            right: 20px;
            top: 20px;
            color: $primary;
            font-family: "gilroy";
            font-weight: 900;
            font-size: 1.3em;
        }
        .altPrice {
            position: absolute;
            right: 20px;
            top: 42px;
            color: $primary;
            font-family: "gilroy";
            font-weight: 900;
            font-size: 1em;
            opacity: 0.6;
        }
        .flight {
            position: absolute;
            right: 20px;
            bottom: 5px;
            color: $primary;
            font-family: "gilroy";
            font-weight: 900;
            font-size: 0.5em;
            opacity: 0.7;
        }
        .disclaimer {
            display: none;
        }
        button {
            position: absolute;
            height: 40px;
            width: 190px;
            right: 20px;
            bottom: 20px;
            border: none;
            background-color: $primary;
            color: white;
            font-family: "gilroy";
            font-weight: 900;
            cursor: pointer;
            &:focus {
                outline: none;
            }
        }
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}