@import "../_palette.scss";
.header {
  width: 100%;
  background-color: white;
  height: 85px;
  display: flex;
  justify-content: center;
  align-items: center;
  a {
    height: 35%;
    width: auto;
  }
  .headerLogo {
    height: 100%;
  }
  .right {
    position: absolute;
    right: 0;
    top: 0;
    margin: 10px 0;
    font-size: 2em;
    color: rgba(black, 0.3);
  }
  .left {
    position: absolute;
    left: 0;
    top: 5px;
    margin: 10px 0;
    font-size: 1.1em;
    color: rgba(black, 0.3);
  }
  .headerContainer {
    width: 100%;
    max-width: $contentWidth;
    margin: 0 auto;
    display: flex;
    position: relative;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    .floater {
      position: absolute;
      left: 0;
      top: 0;
      cursor: pointer;
      display: flex;
      align-items: center;
      img {
        height: 25px;
        margin-right: 10px;
      }
      &.right img {
        margin-right: 0;
        HEIGHT-left: 10px;
      }
      h3 {
        color: rgba(black, 0.3);
      }
    }
  }
  @media screen and (max-width: $contentWidth) {
    .floater {
      left: 15px !important;
    }
    .left {
      display: none;
    }
  }
  @media screen and (max-width: $mobileWidth) {
    .headerLogo {
      width: 80%;
      margin: 0 10%;
    }
    h3 {
      display: none;
    }
    .floater {
      top: 9px !important;
    }
  }
}
