@import '../_palette.scss';
.itineraryListing {
    width: 100%;
    max-width: calc(100vw - 40px);
    margin: 0 auto;
    .titles {
        display: flex;
        align-items: center;

        h2 {
            height: 35px;
            // width: 45px;
            padding: 0 30px;
            
            // border-radius: 100px;
            border-top-left-radius: 100px;
            border-bottom-left-radius: 100px;

            background-color: $primary;
            text-align: center;
            color: white;
            padding-top: 10px;
            margin: 15px 0;
        }

        h3 {
            margin-left: 20px;
        }
    }
    p {
        margin: 0 0 20px 0;
    }

    border-bottom: 1px solid $light;
}