@import "../_palette.scss";
.querySearch {
  margin-bottom: 30px;
  display: flex;
  .searchBar,
  .searchInput {
    width: calc(33% - 10px);
    // background-color: red;
    padding: 10px 0;
  }
  @media screen and (max-width: $contentWidth) {
    flex-wrap: wrap;
    .searchBar {
      width: calc(100% - 10px);
    }
  }
}
