$primary: #2CA5A0;
.modalTitle {
    margin-bottom: 0;
    color: $primary;
}

.modalSubtitle {
    margin-top: 3px;
    margin-bottom: 3px;
}

.modalClose {
    position: absolute;
    top: 40px;
    right: 20px;
    border: none;
    padding: 5px 10px;
}

.tripImage {
    width: calc(100% + 40px);
    margin-left: -20px;
    margin-bottom: -20px;
}

.tripForm {
    label {
        display: inline-block;
        width: 100%;
        max-width: calc(100vw - 45px);
    }
    .tripinput {
        width: 100%;
        max-width: calc(100vw - 45px);
    }
    .subSection {
        background-color: $primary;
        margin-left: -20px;
        width: calc(100%);
        padding: 20px;
        color: white;
        text-align: center;
    }
    .buttonGroup {
        display: flex;
        justify-content: space-around;
        margin: 30px 0;
        button {
            border: none;
            padding: 10px 20px;
            cursor: pointer;
            &.fail {
                background-color: rgba(black, 0.05);
                color: rgba(black, 0.5)
            }
            &.success {
                background-color: $primary;
                color: white;
            }
        }
    }
}