@import "../_palette.scss";
.flightTable {
    width: 100%;
    min-width: 800px;
    margin: 20px 0;
    thead {
        padding: 40px; // background-color: rgba($primary, 0.1);
        th {
            padding: 20px 0;
        }
        span {
            font-family: 'Gilroy' !important;
        }
        text-align: left;
    }
    tbody {
        tr {
            padding: 0 20px;
            &:nth-child(odd) {
                background-color: rgba(black, 0.05);
            }
        }
    }
    button {
        padding: 5px;
        width: 100%;
        border: 0;
        background-color: $primary;
        color: white;
        cursor: pointer;
    }
}

.tableWrapper {
    @media screen and (max-width: $contentWidth) {
        overflow: scroll;
    }
}