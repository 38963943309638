@import '../_palette.scss';
.review {
    // text-align: center;
    margin-bottom: 70px;
    h3 {
        text-align: center;
    }
    h4 {
        padding: 0;
        margin: 0;
    }
    .question {
        p {
            color: $primary;
            margin-bottom: 0;
            &:first-child {
                margin-top: 5px;
            }
        }
        b {
            margin-top: 0;
        }
    }
}

.stars {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    .star {
        height: 17px;
        width: 17px;
        margin: 0 10px;
    }
    svg {
        fill: rgba(black, 0.22);
    }
    .active svg {
        fill: $primary;
    }
}