@import "../_palette.scss";
.adventureHeader {
  $padding: 20px;
  padding: $padding;
  width: calc(100% - #{2 * $padding} - 6px);
  border: 3px solid $primary;
  background-color: $primary;
  color: white;
  justify-content: space-between;
  display: flex;
  margin-top: -88px;

  .element {
    margin: 0 30px;
    span {
    }
    p {
      font-family: "Gilroy";
      font-size: 1.6em;
      padding: 0;
      margin: 0;
    }
  }
  @media screen and (max-width: $contentWidth) {
    flex-wrap: wrap;
    justify-content: center;
    .element {
      margin: 15px;
      p {
        font-size: 1.2em;
      }
    }
  }
  @media screen and (max-width: $mobileWidth) {
      .element {
          width: calc(50% - 30px);
      }
  }
}
