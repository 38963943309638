@import '../_palette.scss';
.searchInput {
    position: relative;
    background-color: white;
    display: inline-block;
    padding: 20px;
    margin: 10px;
    width: calc(40% - 40px - 10px);
    font-weight: 900;
    cursor: pointer;
    .inputContainer {
        width: 100%;
        text-transform: capitalize;
        display: flex;
        .selected {
            font-family: "Lato", sans-serif;
            font-weight: 300;
            background-color: $primary;
            color: white;
            font-size: 0.9em;
            margin-right: 5px;
            padding: 2px 6px;
            border-radius: 4px;
            &:hover {
                background-color: rgba($primary, 0.4);
            }
        }
        input {
            width: 100%;
            border: none;
            &:focus {
                outline: none;
            }
        }
    }
    &:first-child {
        margin-left: 0;
    }
    &:last-child {
        margin-right: 0;
    }
    @media screen and (max-width: $contentWidth) {
        width: calc(100% - 40px - 20px);
        margin-left: 10px !important;
        margin-right: 10px !important;
    }
}