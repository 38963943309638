@import '../_palette.scss';
.nav {
    // position: absolute;
    background-color: white;
    width: 100%;
    flex-direction: column;
    display: flex;
    align-items: center;
    animation: fadeIn 0.05s linear;
    z-index: 100;
    .navLevel {
        width: calc(100% - 30px);
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        h2,
        h3,
        a {
            margin: 10px 20px;
            color: rgba(black, .5);
            &.active {
                color: $primary;
            }
            &:hover {
                color: rgba($primary, .7);
            }
            cursor: pointer;
            text-decoration: none;
        }
        h3 {
            font-size: 1em;
        }
        border-bottom: 1px solid rgba(black, .15);
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0
    }
    100% {
        opacity: 1;
    }
}