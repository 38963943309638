@import '../_palette.scss';
.footer {
    width: 100%;
    background-color: rgba(black, 0.9);
    height: 130px;
    display: flex;

    justify-content: center;
    align-items: center;
    flex-direction: column;

    color: rgba(white, 0.8);
    h4 {
        padding: 0;
        margin: 5px;
        text-align: center;
    }
    h1 {
        padding: 0;
        margin: 0;
    }
}