@import "../_palette.scss";
.searchButtonContainer {
  position: relative;
  background-color: white;
  display: inline-block;
  padding: 10px 20px;
  margin: 10px;
  width: calc(19% - 60px - 10px);
  font-weight: 900;
  background: $primary;
  box-shadow: inset 0 0 0 1px white;
  border: 10px solid $primary;
  cursor: pointer !important;
  button {
    width: 100%;
    padding-top: 12px;
    border: none;
    &:focus {
      outline: none;
    }
    color: white;
    background: $primary;
    text-transform: capitalize;
    cursor: pointer !important;
  }
  margin-right: 0;
  @media screen and (max-width: $contentWidth) {
    width: calc(100% - 60px - 20px);
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
  @media screen and (max-width: $mobileWidth) {
    button {
      width: 100%;
      margin-top: 20px;
    }
  }
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}
